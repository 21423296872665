/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { AiOutlineMenu } from 'react-icons/ai';

const Burger = (props) => {
  return (
    <AiOutlineMenu
      sx={{
        justifyContent: 'center',
        width: props.width,
        height: props.height,
        color: props.color,
      }}
    />
  );
};

export default Burger;
