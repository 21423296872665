/** @jsx jsx */
/* eslint no-unused-vars: 0 */
import { jsx } from 'theme-ui';

import React from 'react';
import { Grid, Flex, Box, Text, Link, Image } from 'theme-ui';
import { Link as GatsbyLink } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans } from '@lingui/macro';
import {
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiFillYoutube,
  AiOutlineShoppingCart,
  AiOutlineCustomerService,
  AiOutlinePhone,
  AiOutlineCreditCard,
} from 'react-icons/ai';
import { FaPinterest } from 'react-icons/fa';

function Footer({ pageContext }) {
  const { hrefLang } = pageContext;
  const year = new Date().getFullYear();

  return (
    <Box bg="backgroundFooter" py={[1, 2, 3, 4]}>
      <Grid
        columns={[1, '1fr 1fr 1fr ']}
        gap={[0, 2, 4, 5]}
        as="footer"
        sx={{ maxWidth: 1300, width: '100%', height: '100%' }}
        mx="auto"
        px={2}
        pt={3}
      >
        <Box sx={{ maxWidth: 400 }}>
          <Text
            color="darkPrimary"
            sx={{
              fontSize: [4],
              textDecoration: 'none',
              fontFamily: 'Berkshire Swash',
              letterSpacing: 2,
              '-webkit-font-smoothing': 'antialiased',
              '-moz-osx-font-smoothing': 'grayscale',
            }}
            mb={1}
          >
            Kisscurls
          </Text>

          <Box mb={[1, 2]}>
            <Link
              href="https://instagram.com/thekisscurls"
              mr={2}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <AiOutlineInstagram size="28" />
            </Link>
            <Link
              href="https://facebook.com/kisscurls"
              mr={2}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <AiOutlineFacebook size="28" />
            </Link>
            <Link
              href="https://www.youtube.com/channel/UCE8i9ANB0-0p75yFNrKA1oA"
              mr={2}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <AiFillYoutube size="28" />
            </Link>
            <Link
              href="https://www.pinterest.com/thekisscurls"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <FaPinterest size="28" />
            </Link>
          </Box>

          <Box mb={[3, 0]}>
            <Box
              sx={{
                fontSize: [1, 2],
                fontFamily: 'sans-serif',
              }}
            >
              <Text as="div" mb={1}>
                <AiOutlineShoppingCart size="16" />{' '}
                <Trans id="Footer.AcceptOrders">Accept orders 24x7</Trans>
              </Text>
              <Text as="div" mb={1}>
                <AiOutlineCustomerService size="16" />{' '}
                <Trans id="Footer.WorkingTime">
                  Mon&mdash;Fri 10:00&mdash;19:00
                </Trans>
              </Text>
              <Text as="div">
                <AiOutlinePhone size="16" /> +375 (29) 183-35-87
              </Text>
            </Box>

            <Flex>
              {/* <Box>
              <StaticImage
                src="../../../../src/images/payments/webpay.png"
                sx={{ maxWidth: 150 }}
                height={42}
              />
            </Box> */}
            </Flex>
          </Box>
        </Box>

        <Box>
          {/* <Box mb={1}>
            <Link as={GatsbyLink} to="/about">
              <Trans id="Footer.AboutLinkLabel">Our story</Trans>
            </Link>
          </Box> */}
          <Box mb={1}>
            <Link as={GatsbyLink} to="/reviews">
              <Trans id="Footer.ReviewsLinkLabel">Customer reviews</Trans>
            </Link>
          </Box>
          <Box mb={1}>
            <Link as={GatsbyLink} to="/guides">
              <Trans id="Footer.GuidesLinkLabel">
                Color, size & care guides
              </Trans>
            </Link>
          </Box>
          <Box mb={1}>
            <Link as={GatsbyLink} to="/faq">
              <Trans id="Footer.FaqLinkLabel">FAQ</Trans>
            </Link>
          </Box>
        </Box>
        <Box mb={[3, 0]}>
          <Box mb={1}>
            <Link as={GatsbyLink} to="/shipping">
              <Trans id="Footer.ShippingLinkLabel">Shipping & Delivery</Trans>
            </Link>
          </Box>
          <Box mb={1}>
            <Link as={GatsbyLink} to="/returns">
              <Trans id="Footer.ReturnsLinkLabel">
                Exchanges, Returns & Refunds
              </Trans>
            </Link>
          </Box>
          {/* <Box mb={1}>
            <Link as={GatsbyLink} to="/terms">
              <Trans id="Footer.TermsLinkLabel">Terms & Conditions</Trans>
            </Link>
          </Box>
          <Box mb={1}>
            <Link as={GatsbyLink} to="/privacy">
              <Trans id="Footer.TermsPrivacyLabel">Privacy policy</Trans>
            </Link>
          </Box> */}
          <Box mb={1}>
            <Link as={GatsbyLink} to="/documents">
              <Trans id="Footer.DocumentsLinkLabel">Documents</Trans>
            </Link>
          </Box>
          <Box mb={1}>
            <Link as={GatsbyLink} to="/contact">
              <Trans id="Footer.ContactLinkLabel">Contact us</Trans>
            </Link>
          </Box>
        </Box>
      </Grid>

      <Flex sx={{ maxWidth: 1300, justifyContent: 'center' }} mx="auto">
        <Box mr={3} sx={{ opacity: 0.7 }}>
          {hrefLang === 'ru-BY' ? (
            <StaticImage
              src="../../../../src/images/payments/payments-by.png"
              alt="Visa"
              objectFit="contain"
              sx={{ maxWidth: [350, 500, 650] }}
            />
          ) : (
            <StaticImage
              src="../../../../src/images/payments/payments.png"
              alt="Visa"
              objectFit="contain"
              sx={{ maxWidth: [350, 500] }}
            />
          )}
        </Box>
        {/* <Box mr={3} sx={{ opacity: 0.7 }}>
          <StaticImage
            src="../../../../src/images/payments/visa.png"
            alt="Visa"
            objectFit="contain"
            sx={{ maxWidth: 100 }}
          />
        </Box>
        <Box mr={3} sx={{ opacity: 0.7 }}>
          <StaticImage
            src="../../../../src/images/payments/mastercard.png"
            alt="MasterCard"
            objectFit="contain"
            sx={{ maxWidth: 100 }}
          />
        </Box>
        <Box
          sx={{
            display: hrefLang === 'ru-BY' ? 'block' : 'none',
            opacity: 0.7,
          }}
        >
          <StaticImage
            src="../../../../src/images/payments/erip.png"
            alt="ЕРИП"
            sx={{ maxWidth: 100, maxHeight: 18 }}
          />
        </Box> */}
      </Flex>
      <Flex
        sx={{ maxWidth: 1300, flexWrap: 'wrap', justifyContent: 'center' }}
        mx="auto"
        px={[2, 0]}
      >
        <Text
          as="div"
          sx={{
            mt: 2,
            mr: 2,

            fontSize: '12px',
            opacity: 0.5,
            fontFamily: 'sans-serif',
          }}
        >
          <Trans id="Footer.Copyright">© Copyright {year} Kisscurls</Trans>
        </Text>
        <Text
          as="div"
          sx={{
            mt: 2,
            fontSize: '12px',
            opacity: 0.5,
            fontFamily: 'sans-serif',
          }}
        >
          {hrefLang === 'ru-BY' ? (
            <Trans id="Footer.LegalNote.Belarus">
              ИП Иванова А.Ю. 220035 Беларусь г. Минск ул. Репина 4-20.
              Регистрация №193550029, 21.05.2021, Мингорисполком. В торговом
              реестре с 07.06.2021, № регистрации 511755.
            </Trans>
          ) : (
            ''
          )}
        </Text>
      </Flex>
    </Box>
  );
}

export default React.memo(Footer);
