/** @jsx jsx */
import { jsx } from 'theme-ui';

import React, { useState } from 'react';
import { Flex, Text, Box, Link, Heading } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import { Link as GatsbyLink } from 'gatsby';
import styled from '@emotion/styled';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Trans } from '@lingui/macro';
import { BsDot } from 'react-icons/bs';

import { useShopifyFunctions } from '../hooks/useShopifyFunctions';
import Menu from './Menu';
import Search from './Search';
import ShoppingBag from './Icons/ShoppingBag';
import ShoppingCart from './Icons/ShoppingCart';
import RegionSelector from './RegionSelector';
import strings from './strings.json';

const { ariaShoppingCartLabel, ariaHomaPageLinkLabel } = strings;

const NavbarBase = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
`;

const Nav = styled(NavbarBase)`
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.2);
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition: all 200ms ${(props) => (props.show ? 'ease-in' : 'ease-out')};
  transform: ${(props) => (props.show ? 'none' : 'translate(0, -100%)')};
`;

const Navbar = (props) => {
  const [hideNavbarOnScroll, setHideNavbarOnScroll] = useState(true);

  const {
    checkout: { lineItems, subtotalPrice },
  } = useShopifyFunctions();

  useScrollPosition(
    ({ prevPos, currPos }) => {
      // Note: prevPos.y > -12 is here to fix Nav component disappearing bug
      // due to elastic scrolling/bounce effect in mobile Safari.
      const isShow = currPos.y > prevPos.y || prevPos.y > -12;
      if (isShow !== hideNavbarOnScroll) setHideNavbarOnScroll(isShow);
    },
    [hideNavbarOnScroll],
    null,
    false,
    100
  );

  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          gatsbyStorefrontConfig {
            storeName
            logo {
              url
              width
              height
            }
            menu {
              handle
              id
              link
              name
              parentId
              type
            }
          }
        }
      }
    }
  `);

  const {
    storeName,
    logo,
    menu,
  } = data.site.siteMetadata.gatsbyStorefrontConfig;

  const { path } = props;

  return (
    <Nav show={hideNavbarOnScroll}>
      <Box py={[2, 3]} width={1} as="nav" bg="white">
        <Flex
          sx={{
            maxWidth: 1300,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          mx="auto"
          px={[3, null, 4]}
        >
          <Flex sx={{ width: [80, 150], alignItems: 'center' }}>
            <Box sx={{ display: ['block', 'block', 'none'] }}>
              <Menu menu={menu} path={path} />
            </Box>
            <Box
              sx={{
                display: ['none', 'none', 'block'],
              }}
            >
              <RegionSelector path={path} />
            </Box>
          </Flex>

          <Link
            as={GatsbyLink}
            to="/"
            aria-label={ariaHomaPageLinkLabel}
            sx={{ textDecoration: 'none' }}
            ml="auto"
          >
            {logo ? (
              <img
                src={logo.url}
                width={logo.width}
                height={logo.height}
                alt={storeName}
              />
            ) : (
              <Flex>
                <Heading
                  ml={2}
                  color="darkPrimary"
                  sx={{
                    fontSize: [4, 5, '36px'],
                    textDecoration: 'none',
                    fontFamily: 'Berkshire Swash',
                    letterSpacing: 2,
                    '-webkit-font-smoothing': 'antialiased',
                    '-moz-osx-font-smoothing': 'grayscale',
                  }}
                >
                  {storeName}
                </Heading>
              </Flex>
            )}
          </Link>

          <Flex ml="auto" sx={{ width: [80, 150], alignItems: 'center' }}>
            {/* <Box ml="auto">
              <Search width="25px" height="25px" color="primary" />
            </Box> */}

            {/* <Box ml="auto" sx={{ display: ['block', 'none'] }}>
              <RegionSelector />
            </Box> */}

            <Text
              as={GatsbyLink}
              aria-label={ariaShoppingCartLabel}
              to="/cart"
              sx={{ textDecoration: 'none', fontSize: 4 }}
              ml="auto"
            >
              <Flex>
                {subtotalPrice > 0 && lineItems.length > 0 ? (
                  <BsDot
                    size="36"
                    sx={{
                      position: 'relative',
                      top: -3,
                      right: -52,
                      color: 'secondary',
                    }}
                  />
                ) : (
                  ''
                )}
                <ShoppingCart width={36} height={36} color="black" />
              </Flex>
            </Text>
          </Flex>
        </Flex>
      </Box>
      <Box
        py={[2, 3]}
        as="nav"
        bg="white"
        sx={{
          width: '100%',
          textAlign: 'center',
          display: ['none', 'none', 'block'],
        }}
      >
        {path && path !== '/' ? (
          <Link as={GatsbyLink} variant="topmenu" to="/">
            <Trans>Home</Trans>
          </Link>
        ) : (
          ''
        )}

        {menu.map((e, i) => (
          <Link
            as={GatsbyLink}
            variant="topmenu"
            to={`/${e.type ? e.type + '/' : ''}${e.handle}`}
            key={i}
          >
            {e.name}
          </Link>
        ))}
      </Box>
    </Nav>
  );
};

export default React.memo(Navbar);
