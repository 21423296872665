/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';

import Globe from '../Icons/Globe';
import BelarusFlag from '../Icons/BelarusFlag';

const RegionFlag = ({ name }) => {
  return (
    <>
      {name === 'Belarus' ? (
        <BelarusFlag color="black" width={27} height={26} />
      ) : (
        ''
      )}
      {name === 'World' ? <Globe color="black" width={27} height={28} /> : ''}
    </>
  );
};

export default RegionFlag;
