// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---plugins-storefront-src-pages-404-jsx": () => import("./../../../plugins/storefront/src/pages/404.jsx" /* webpackChunkName: "component---plugins-storefront-src-pages-404-jsx" */),
  "component---plugins-storefront-src-templates-cart-index-jsx": () => import("./../../../plugins/storefront/src/templates/cart/index.jsx" /* webpackChunkName: "component---plugins-storefront-src-templates-cart-index-jsx" */),
  "component---plugins-storefront-src-templates-catalog-index-jsx": () => import("./../../../plugins/storefront/src/templates/catalog/index.jsx" /* webpackChunkName: "component---plugins-storefront-src-templates-catalog-index-jsx" */),
  "component---plugins-storefront-src-templates-product-index-jsx": () => import("./../../../plugins/storefront/src/templates/product/index.jsx" /* webpackChunkName: "component---plugins-storefront-src-templates-product-index-jsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-documents-js": () => import("./../../../src/pages/documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-guides-choose-hair-color-js": () => import("./../../../src/pages/guides/choose-hair-color.js" /* webpackChunkName: "component---src-pages-guides-choose-hair-color-js" */),
  "component---src-pages-guides-hair-care-guide-js": () => import("./../../../src/pages/guides/hair-care-guide.js" /* webpackChunkName: "component---src-pages-guides-hair-care-guide-js" */),
  "component---src-pages-guides-how-to-choose-hair-color-js": () => import("./../../../src/pages/guides/how-to-choose-hair-color.js" /* webpackChunkName: "component---src-pages-guides-how-to-choose-hair-color-js" */),
  "component---src-pages-guides-how-to-choose-hair-size-js": () => import("./../../../src/pages/guides/how-to-choose-hair-size.js" /* webpackChunkName: "component---src-pages-guides-how-to-choose-hair-size-js" */),
  "component---src-pages-guides-index-js": () => import("./../../../src/pages/guides/index.js" /* webpackChunkName: "component---src-pages-guides-index-js" */),
  "component---src-pages-guides-send-hair-photos-js": () => import("./../../../src/pages/guides/send-hair-photos.js" /* webpackChunkName: "component---src-pages-guides-send-hair-photos-js" */),
  "component---src-pages-guides-video-guide-js": () => import("./../../../src/pages/guides/video-guide.js" /* webpackChunkName: "component---src-pages-guides-video-guide-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-monotapes-js": () => import("./../../../src/pages/monotapes.js" /* webpackChunkName: "component---src-pages-monotapes-js" */),
  "component---src-pages-order-cancel-js": () => import("./../../../src/pages/order/cancel.js" /* webpackChunkName: "component---src-pages-order-cancel-js" */),
  "component---src-pages-order-success-js": () => import("./../../../src/pages/order/success.js" /* webpackChunkName: "component---src-pages-order-success-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-returns-js": () => import("./../../../src/pages/returns.js" /* webpackChunkName: "component---src-pages-returns-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-shipping-js": () => import("./../../../src/pages/shipping.js" /* webpackChunkName: "component---src-pages-shipping-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

