/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Flex } from 'theme-ui';
import { AiOutlineShopping } from 'react-icons/ai';

const ShoppingCart = (props) => {
  return (
    <Flex
      sx={{
        justifyContent: 'center',
      }}
    >
      <AiOutlineShopping
        sx={{
          justifyContent: 'center',
          width: props.width,
          height: props.height,
          color: props.color,
        }}
      />
    </Flex>
  );
};

export default ShoppingCart;
