/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Flex } from 'theme-ui';
import { IoFlowerOutline } from 'react-icons/io5';

const BelarusFlag = (props) => {
  return (
    <IoFlowerOutline
      sx={{
        justifyContent: 'center',
        width: props.width,
        height: props.height,
      }}
    />
    // <svg
    //   width={props.width ? props.width : '100%'}
    //   height={props.height ? props.height : '100%'}
    //   viewBox="0 0 900 450"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <rect fill="#ce1720" width="100%" height="100%" />
    //   <path d="m5 0h100v450h-100z" fill="#fff" />
    //   <g id="a">
    //     <path
    //       id="b"
    //       d="m572-0v163h-44v-163zm-562 285h79v-65h40v-79h56v-69h46v-72h139v72h45v69h56v79h40v65h61v101h-61v65h-40v79h-56v69h-45v74h-32v62h-75v-62h-32v-74h-46v-69h-56v-79h-40v-65h-79m0-352h79v90h-79m310 27h-41v61h-39v77h-38v95h38v77h39v60h41v-60h39v-77h39v-95h-39v-77h-39zm4 139v90h-49v-90zm-314 236h79v97h-79m562-88v137h-44v-137zm-108 152v68h48v70h60v92h-60v71h-48v68h-56v-68h-48v-71h-40v-92h40v-70h48v-68zm-454 138h79v-70h48v-68h55v68h48v70h40v92h-40v71h-48v68h-55v-68h-48v-71h-79m447-90h-41v87h41zm-271 0h-41v87h41zm16 1441v-47h-34v-71h-45v-43h-34v-69h-79v-160h79v46h34v67h45v80h64v-72h47v-53h34v-63h45v-67h33v-68h32v-70h43v-94h-57v-85h-62v-51h-104v74h60v67h-40v82h-47v72h-59v-72h-49v-82h-30v-67h-68v-127h79v-82h30v-63h28v-72h40v-71h39v-47h47v-71h57v71h47v47h40v71h39v72h32v62h32v54h52v397h-59v67h-17v70h-44v68h-27v50h-28v53h-48v52h-69v145m292-1213v137h-44v-137zm-562 26h79v97h-79m0 486h79v97h-79zm560 246v88h-42v-88zm-58 358v-55h-26v-43h-38v-129h38v52h40v76h44v98m-214 0v-64h42v64"
    //       fill="#ce1720"
    //       transform="scale(.1)"
    //     />
    //     <use transform="matrix(-1 0 0 1 110 0)" xlinkHref="#b" />
    //   </g>
    //   <use transform="matrix(1 0 0 -1 0 450)" xlinkHref="#a" />
    //   <path d="m105 300h795v150h-795z" fill="#007c30" />
    // </svg>
    // </Flex>
  );
};

export default BelarusFlag;
