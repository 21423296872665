/** @jsx jsx */
import { jsx } from 'theme-ui';

import React, { useState } from 'react';
import { Flex, Box, Text, Link } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';

import RegionFlag from '../RegionFlag';

const RegionSelector = ({ path }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          gatsbyStorefrontConfig {
            currency
          }
          i18n {
            locale
            hrefLang
            alternates
            name
            nameEng
          }
        }
      }
    }
  `);

  const {
    locale,
    hrefLang,
    alternates: alternatesRAW,
    name,
    nameEng,
  } = data.site.siteMetadata.i18n;
  const { currency } = data.site.siteMetadata.gatsbyStorefrontConfig;
  const alternates = JSON.parse(alternatesRAW).filter((a) => a.name !== name);

  const [showSelector, setShowSelector] = useState(false);

  return (
    <Box
      onMouseEnter={() => {
        setShowSelector(!showSelector);
      }}
      onMouseLeave={() => {
        setShowSelector(!showSelector);
      }}
    >
      <Flex sx={{ alignItems: 'center', cursor: 'pointer' }}>
        <RegionFlag name={nameEng} />

        <Text
          sx={{
            ml: 1,
            fontSize: 2,
          }}
        >
          {name} | {currency}
        </Text>
      </Flex>
      {showSelector ? (
        <Box sx={{ position: 'absolute' }}>
          {alternates.map((a, i) => (
            <Link href={a.urlPrefix} key={i}>
              <Flex sx={{ alignItems: 'center', cursor: 'pointer' }} mt={1}>
                <RegionFlag name={a.nameEng} />

                <Text
                  sx={{
                    ml: 1,
                    fontSize: 2,
                  }}
                >
                  {a.name} | {a.currency}
                </Text>
              </Flex>
            </Link>
          ))}
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
};

export default RegionSelector;
