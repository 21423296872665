/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Flex } from 'theme-ui';
import { AiOutlineClose } from 'react-icons/ai';

const Close = (props) => {
  return (
    <AiOutlineClose
      sx={{
        justifyContent: 'center',
        width: props.width,
        height: props.height,
      }}
    />
  );
};

export default Close;
