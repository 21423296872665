import * as React from 'react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { stripIndents } from 'common-tags';

import { en, ru } from 'make-plural/plurals';

const wrapPageElement = ({ element, props }) => {
  const locale = props.pageContext.locale;
  const catalog = require(`../../i18n/lingui/${locale}/messages.js`);

  i18n.loadLocaleData(locale, {
    plurals: () => {
      switch (locale) {
        case 'en':
          return en;
        case 'ru':
          return ru;
      }
    },
  });
  i18n.load(locale, catalog.messages);
  i18n.activate(locale);

  const { hrefLang } = props.pageContext;

  return (
    <I18nProvider i18n={i18n}>
      <>{element}</>
      <>
        {hrefLang !== 'ru-BY' ? (
          <>
            <div id="fb-root"></div>
            <div id="fb-customer-chat" className="fb-customerchat"></div>

            <script
              dangerouslySetInnerHTML={{
                __html: stripIndents`
                          var chatbox = document.getElementById('fb-customer-chat');
                          chatbox.setAttribute("page_id", "102439938537634");
                          chatbox.setAttribute("attribution", "biz_inbox");
                          window.fbAsyncInit = function() {
                            FB.init({
                              xfbml            : true,
                              version          : 'v11.0'
                            });
                          };

                          (function(d, s, id) {
                            var js, fjs = d.getElementsByTagName(s)[0];
                            if (d.getElementById(id)) return;
                            js = d.createElement(s); js.id = id;
                            js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
                            fjs.parentNode.insertBefore(js, fjs);
                          }(document, 'script', 'facebook-jssdk'));
                        `,
              }}
            />
          </>
        ) : (
          <>
            <div id="fb-root"></div>
            <div id="fb-customer-chat" className="fb-customerchat"></div>

            <script
              dangerouslySetInnerHTML={{
                __html: stripIndents`
                          var chatbox = document.getElementById('fb-customer-chat');
                          chatbox.setAttribute("page_id", "102439938537634");
                          chatbox.setAttribute("attribution", "biz_inbox");
                          window.fbAsyncInit = function() {
                            FB.init({
                              xfbml            : true,
                              version          : 'v11.0'
                            });
                          };

                          (function(d, s, id) {
                            var js, fjs = d.getElementsByTagName(s)[0];
                            if (d.getElementById(id)) return;
                            js = d.createElement(s); js.id = id;
                            js.src = 'https://connect.facebook.net/ru_RU/sdk/xfbml.customerchat.js';
                            fjs.parentNode.insertBefore(js, fjs);
                          }(document, 'script', 'facebook-jssdk'));
                        `,
              }}
            />
          </>
        )}
      </>
    </I18nProvider>
  );
};

export { wrapPageElement };
