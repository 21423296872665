/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { VscGlobe } from 'react-icons/vsc';

const Globe = (props) => {
  return (
    <VscGlobe
      sx={{
        justifyContent: 'center',
        width: props.width,
        height: props.height,
        color: props.color,
      }}
    />
  );
};

export default Globe;
