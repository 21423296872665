/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import Helmet from 'react-helmet';
import { Flex, Box } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';

import { ShopifyFunctionsContextProvider } from '../hooks/useShopifyFunctions.js';
import { MenuContextProvider } from './Menu/context';

import { SearchContextProvider } from './Search/context';
import Navbar from './Navbar';
import Footer from './Footer';

import './reset.css';

const Layout = ({ children, path, pageContext }) => {
  return (
    <ShopifyFunctionsContextProvider>
      <LayoutComponents
        children={children}
        path={path}
        pageContext={pageContext}
      />
    </ShopifyFunctionsContextProvider>
  );
};

const LayoutComponents = ({ children, path, pageContext }) => {
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            assetPrefix
          }
        }
      }
    `
  );

  const { hrefLang } = pageContext;

  const { assetPrefix } = data.site.siteMetadata;

  const alternates = JSON.parse(pageContext.alternates);
  return (
    <Box
      bg="background"
      sx={{
        width: '100%',
      }}
    >
      <Helmet>
        <html lang={pageContext.hrefLang} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
        <link rel="preconnect" href="https://cdn.shopify.com" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        {assetPrefix ? <link rel="preconnect" href={assetPrefix} /> : ''}
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://kisscurls.com"
        />
        {alternates.map((a, i) => (
          <link
            rel="alternate"
            hrefLang={a.hreflang}
            href={a.urlPrefix + path}
            key={i}
          />
        ))}
      </Helmet>

      <Flex sx={{ minHeight: '100vh', flexDirection: 'column' }}>
        <MenuContextProvider>
          <SearchContextProvider>
            <Navbar path={path} />
          </SearchContextProvider>
        </MenuContextProvider>

        <Box
          as="main"
          sx={{
            flex: '1',
            width: '100%',
            height: '100%',
            mx: 'auto',
            mt: [55, 55, 125],
            p: 0,
          }}
        >
          {children}
        </Box>

        <Footer pageContext={pageContext} />
      </Flex>
    </Box>
  );
};

export default Layout;
